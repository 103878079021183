import { Component, inject, input } from '@angular/core';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { NgClass } from '@angular/common';

import { FaqItem } from '@data-content/faq';
import { MarkdownParserDirective } from '@ui/markdown-parser.directive';
import { AnalyticsService } from '@data/analytics/analytics.service';
import { CustomIconsModule } from '@ui/custom-icons/custom-icons.module';

@Component({
  selector: 'ui-faq-item',
  standalone: true,
  imports: [
    CdkAccordionModule,
    NgClass,
    MarkdownParserDirective,
    CustomIconsModule,
  ],
  templateUrl: './faq-item.component.html',
})
export class FaqItemComponent {
  data = input.required<FaqItem>();
  analyticsService = inject(AnalyticsService);

  sendEvent(isExpanded: boolean): void {
    if (isExpanded) {
      this.analyticsService.pushEvent(
        this.analyticsService.createFaqImpressionEvent({
          faq_cms_key: this.data().questionKey,
          faq_tag: this.data().tags.toString(),
          faq_category: this.data().category,
        }),
      );
    }
  }
}
