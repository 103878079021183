<cdk-accordion>
  <div class="mb-16 flex flex-col gap-8">
    @for (item of list().data; track $index) {
      <ui-faq-item [data]="item.attributes"></ui-faq-item>
    }
  </div>
</cdk-accordion>
@if (pageCount()) {
  <div class="flex justify-between self-stretch pt-4 align-bottom">
    <button (click)="previous.emit()" title="Previous page">
      <div
        class="flex border-spacing-px items-center justify-center gap-2 rounded-lg border border-solid border-gray-300 p-1.5 pl-2.5 pr-2.5 shadow-sm"
      >
        <fa-icon [icon]="['far', 'arrow-left']"></fa-icon>
      </div>
    </button>
    <div class="flex flex-col justify-center">
      Page {{ list().meta.pagination.page }} of {{ pageCount() }}
    </div>
    <button (click)="next.emit()" title="Next page">
      <div
        class="flex border-spacing-px items-center justify-center gap-2 rounded-lg border border-solid border-gray-300 p-1.5 pl-2.5 pr-2.5 shadow-sm"
      >
        <fa-icon [icon]="['far', 'arrow-right']"></fa-icon>
      </div>
    </button>
  </div>
}
