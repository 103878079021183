import { CdkAccordion } from '@angular/cdk/accordion';
import { Component, input, output } from '@angular/core';
import { FaqItemComponent } from '../faq-item/faq-item.component';
import { FaqList } from '@data-content/faq';
import { CustomIconsModule } from '@ui/custom-icons/custom-icons.module';

@Component({
  selector: 'ui-faq-list',
  standalone: true,
  imports: [CdkAccordion, FaqItemComponent, CustomIconsModule],
  templateUrl: './faq-list.component.html',
})
export class FaqListComponent {
  list = input.required<FaqList>();
  pageCount = input<number>();
  next = output();
  previous = output();
}
