<cdk-accordion-item
  #accordionItem="cdkAccordionItem"
  role="button"
  tabindex="0"
  [attr.id]="'accordion-header-' + data().question"
  [attr.aria-expanded]="accordionItem.expanded"
  [attr.aria-controls]="'accordion-body-' + data().question"
>
  <div
    class="border-t border-gray-200 pt-6"
    (click)="accordionItem.toggle(); sendEvent(accordionItem.expanded)"
  >
    <div class="flex justify-between">
      <p class="text-lg font-medium">{{ data().question }}</p>
      <div class="text-gray-400">
        @if (accordionItem.expanded) {
          <fa-icon class="fa-xl" [icon]="['fal', 'circle-minus']"></fa-icon>
        } @else {
          <fa-icon class="fa-xl" [icon]="['fal', 'circle-plus']"></fa-icon>
        }
      </div>
    </div>
  </div>
  <div
    class="overflow-hidden text-gray-600 transition-all"
    role="region"
    [ngClass]="
      accordionItem.expanded
        ? 'relative mt-2 opacity-100'
        : 'pointer-events-none absolute max-h-0 opacity-0'
    "
    [attr.id]="'accordion-body-' + data().question"
    [attr.aria-labelledby]="'accordion-header-' + data().question"
    [uiMarkdownParser]="data().answer"
  ></div>
</cdk-accordion-item>
