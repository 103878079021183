import { Observable } from 'rxjs';
import { ContentService } from './content.service';
import { FaqItemsFilterQuery, FaqList } from './faq';
import qs from 'qs';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FaqContentService extends ContentService {
  http = inject(HttpClient);

  public faqItems$(filter: Partial<FaqItemsFilterQuery>): Observable<FaqList> {
    return this.http.get<FaqList>(
      `${this.baseUrl}faq-items?${qs.stringify(
        {
          filters: {
            category: {
              $eq: filter.category,
            },
            tags: {
              $containsi: filter.tag ? `"${filter.tag}"` : null,
            },
          },
          pagination: {
            ...(filter.pagination ?? { page: 0, pageSize: 10 }),
          },
        },
        { skipNulls: true },
      )}`,
    );
  }
}
